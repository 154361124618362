import React from 'react'
import Layout from '../LayoutView'
import PropertyDetailsSection from './property-details'


const PropertyDetails = ({ data }) => {
  const { contentfulProperty } = data

  return (
    <Layout>
      <PropertyDetailsSection property={contentfulProperty}/>
    </Layout>
  )
}

export default PropertyDetails
