import React from 'react'
import Agent from './Agent'
import Description from './Description'
import OtherFeatures from './OtherFeatures'
import PropertyImageSlider from './PropertyImageSlider'
import GenerateMap from '../map'
import { Card } from 'react-bootstrap'
import Breadcrumb from "./breadcrumb";
import { Link } from "gatsby"
import { BreadcrumbJsonLd, ProductJsonLd, GatsbySeo } from 'gatsby-plugin-next-seo'
import './property.css'
import { GatsbyImage as Img, getImage, getSrc } from 'gatsby-plugin-image'
import Contact2 from './Contact2'
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa'
// import {getImageVariant, useSiteMetadata} from '../../utils'
// import {getImageVariant, useSiteMetadata} from '../../utils'

const PropertyDetails = ({ property, siteUrl, otherImages, images, heroImage }) => {

  const title = `${property.name} in ${property.location} ` 
  const locale = `${property.locality.name}`
  const localeSlug = `property/${property.propertyCategory.slug}/${property.propertyType.slug}/${property.locality.city.slug}/${property.locality.slug}`
  const city = `property/${property.propertyCategory.slug}/${property.propertyType.slug}/${property.locality.city.slug}`
  const cityName = `${property.locality.city.name}`
  const company = `${property.agent.name}`
  const price = `${property.newerprice}`
  const description = `${property.description.childMarkdownRemark.html}`
    const url = `property/${property.propertyCategory.slug}/${property.propertyType.slug}/${property.locality.city.slug}/${property.locality.slug}/${property.slug}`
  const pricefix = `${price.replace(/,/g, "")}`
  const hero =`${property.heroImage}`
  const pricestring = `${pricefix.toString()}`
  // const site = useSiteMetadata()
  
 //const imageSrc = getSrc(getImageVariant(property.heroImage))

 // const imageUrl =imageSrc && (imageSrc.startsWith('//') ? imageSrc : siteUrl && `'ownahome.ng'${imageSrc}`)
  

    {/*const company = `${agent.name}`*/}
  const prod =`${property.name}`
  const prodImage = `${property.otherImages.map((image, index) => (
    <Img
      className='d-block w-100 mb-0'
      key={index}
      image={getImage(image)}
      alt='slides'
    />
    
  ))}`
  
  const items = [
    { to: '/', label: 'Home' },
    {to: `/${city}`, label: cityName },
    { to: `/${localeSlug}`, label: locale },
    { label: prod }
  ]
  
  return (
    
    <div className='property-details-area'>
      <div className='bg-gray pd-top-100 pb-4'>
        <div className='container'>
          <div className='row '>
            <div className='col-xl-9 col-lg-8'>
            <GatsbySeo
      title={title}
      description= {description}
     
      
      openGraph={{
        title: {title},
        description: {description},
        url: '',
        images: [
          {
            url: '',
            width: 850,
            height: 650,
            alt: 'ownahome.ng image',
          }
        ],
        site_name: 'Owning a home does not need to make you lose sleep',
      }}
      twitter={{
        handle: '@ownahome_ng',
        site: '@ownahome.ng',
        cardType: 'summary_large_image',
      }}
    />

<BreadcrumbJsonLd
      itemListElements={[
        {
          position: 1,
          name: 'Home',
          item: '/',
        },
        {
          position: 2,
          name: cityName,
          item: `/${city}`,
        },

        {
          position: 3,
          name: locale,
          item: `/${localeSlug}`,
        },
        {
          position: 4,
          name: {prod},
          item: `/${url}`,
        },
      ]}
    />

<ProductJsonLd
      productName={prod}
      images={[
        {prodImage}
      ]}
      description={description}
      brand='Ownahome.ng'
      offers={{
        price: `${pricestring}`,
        priceCurrency: 'NGN',
        priceValidUntil: '2020-11-05',
        itemCondition: 'New',
        availability: 'InStock ',
        url: `/${url}`,
        businessFunction : 'Sell',
        additionalType: 'http://schema.org/RealEstateListing',
        propertyType: `${property.propertyType.name}`,
        seller: {
          name: {company},
        },
      }}
      mpn='925872'
    />

            <Breadcrumb >
              {items.map(({ to, label }) => (
                <Link key={to} to={to}>
                  {label}
                </Link>
              ))}
            </Breadcrumb>
              <PropertyImageSlider {...property} />
            </div>
            
            


            <div className='col-xl-3 col-lg-4'>
              <Agent agent={property.agent} />
            </div>
          </div>
        </div>
      </div>
    
      
     
      <div className='container'>
        <div className='row pd-top-90'>
          <div className='col-lg-9'>
         
            <Description {...property} />
            <OtherFeatures otherFeatures={property.otherFeatures} />
            
            
           
            <a href="mailto:emordiuche@ownahome.ng" className="mb-4 btn btn-primary w-100"><FaEnvelope className='mx-1 h4'/>Send Email</a>
            <div>
            {/* property.brochure.file.url &&
              <div classname="container">
                <p className="h4 d-flex justify-content-center">For Payment Plans and Frequently Asked Questions</p>
                <a className="btn btn-outline-info btn-block" href={property.brochure.file.url} target="_blank" rel="noreferrer">Download Brochure</a>
              </div>
            */}
          </div>  

            
           
            <Card>
             
              <Card.Header><FaMapMarkerAlt/>{property.location}</Card.Header>
              <GenerateMap className="mb-2" locality={property.locality} />
            </Card>
            <div className='container mt-3 mb-2'>
            <Contact2/>
            </div>
            
            {/*<div className='property-news-single-card border-bottom-yellow mb-0'>
              <h4>Large image</h4>
              <div className='thumb'>
                <Img
                  image={getImage(property.heroImage)}
                  className='d-block w-100 mb-2'
                  alt='large image'
                />
              </div>
            </div>
            */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PropertyDetails
