import React from 'react'
import Slider from './Slider'
import { FaBath, FaVectorSquare, FaBed, FaUser, FaMapMarkerAlt } from 'react-icons/fa'

const PropertyImageSlider = ({
  name,
  newerprice,
  olderprice,
  otherImages,
  location
}) => {
  return (
    <div>
      <div className='property-details-slider-info mb-0'>
        <h1 className="h2">{name}</h1>
        <h2 className='text-muted h5'><FaMapMarkerAlt/>{location}</h2>
        <div className='d-flex align-items-center my-1'>
          <h2 className='text-success h5'>₦{newerprice} </h2>{' '}
          {olderprice && (
            <React.Fragment>
              <span className='mx-2'>-</span>
              <h2 className='text-success h5'>₦{olderprice}</h2>
            </React.Fragment>
          )}
        </div>
      </div>
      <div className='property-details-slider'>
        <Slider images={otherImages}/>
      </div>
    </div>
  )
}

export default PropertyImageSlider
