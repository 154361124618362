import React, { useEffect, useRef, useCallback } from 'react'
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import mbxGeocoding from '@mapbox/mapbox-sdk/services/geocoding'

import './map.css'

const GenerateMap = ({ locality, localAddress }) => {
  const address = localAddress
    ? localAddress
    : `${locality.name},${locality.city.name}`
  const mapContainerRef = useRef(null)
  mapboxgl.accessToken = process.env.GATSBY_MAPBOX_API

  const fetchData = useCallback(() => {
    const geocodingClient = mbxGeocoding({
      accessToken: mapboxgl.accessToken
    })

    // geocoding with countries
    return geocodingClient
      .forwardGeocode({
        query: address,
        countries: ['ng'],
        limit: 2
      })
      .send()
      .then(response => {
        const match = response.body
        const coordinates = match.features[0].geometry.coordinates
        const placeName = match.features[0].place_name
        const center = match.features[0].center
        return {
          type: 'Feature',
          center: center,
          geometry: {
            type: 'Point',
            coordinates: coordinates
          },
          properties: {
            description: placeName
          }
        }
      })
  }, [])

  useEffect(() => {
    const results = fetchData()
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      zoom: 12,
      center: [3.361881, 6.672557]
    })

    results.then(marker => {
      // create a HTML element for each feature
      var el = document.createElement('div')
      el.className = 'marker'

      // make a marker for each feature and add it to the map
      new mapboxgl.Marker(el)
        .setLngLat(marker.geometry.coordinates)
        .setPopup(
          new mapboxgl.Popup({ offset: 25 }) // add popups
            .setHTML('<p>' + marker.properties.description + '</p>')
        )
        .addTo(map)

      map.on('load', async () => {
        map.flyTo({
          center: marker.center
        })
      })
    })

    // clean up on unmount
    return () => map.remove()
  }, [fetchData])

  return (
    <div className=''>
      <div
        ref={mapContainerRef}
        style={{ width: '100%', height: '60vh', borderRadius: '5px' }}
      />
    </div>
  )
}

export default GenerateMap
