import React from 'react'
import { Link } from 'gatsby'
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa'
import { getImage, GatsbyImage as Img } from 'gatsby-plugin-image'
import Contact from './Contact'

const Agent = ({ agent }) => {
  return (
    <div className='widget widget-owner-info mt-lg-0'>
      {agent && (
        <>
          <div className='owner-info text-center'>
            <Link to={`/real-estate-companies/${agent.slug}`}>
              <div
                className='thumb'
                style={{ width: '150px', maxHeight: '100px' }}
              >
                <Img
                  image={getImage(agent.logo)}
                  alt='agent'
                  className='mx-auto'
                />
              </div>
            </Link>
            <div className='details'>
              <h2 className='title readeal-top h6'>
                <Link to={`/real-estate-companies/${agent.slug}`}>{agent.name}</Link>
              </h2>
            </div>
          </div>
          <div className='contact-info'>
    
            <div className='media d-flex'>
              <div className='media-left'>
                <FaMapMarkerAlt />
              </div>
              <div className='media-body'>
                <p>Office Address</p>
                <span
                  dangerouslySetInnerHTML={{
                    __html: agent.address.childMarkdownRemark.html
                  }}
                />
               <h2 className="h6"><span>{agent.address.address}</span></h2> 
              </div>
            </div>
            <div className='media d-flex'>
              <div className='media-left'>
               
                <span>
                  <a
                    href='tel: +2348101536132'
                    className='btn btn-danger w-100'
                  >
                    <FaPhoneAlt className='h5' /> Call Developer
                  </a>
                </span>
              </div>
              <div className='media-body'>
                {/*<p>Phone</p>*/}
                {/*<span><a href={"tel:" + agent.phoneNumber.join(', ')} style={{ textDecoration: 'none', color: 'blue'}}>{agent.phoneNumber.join(', ')}</a></span> */}
                
              </div>
            </div>
            
            <div className='media d-flex mt-5 mb-0'>
            
              <div className='media-left'>
              <span>
              <a className='  mt-2'
                    href='mailto:emordiuche@ownahome.ng'>
                      <FaEnvelope className='h4 mx-1' />Send Email
                      </a></span>
                      </div>
            </div>
            
          </div>{' '}
          
        </>
      )}
      <Contact/>
    </div>
  )
}

export default Agent
