import React from 'react'
import { FaBath, FaVectorSquare, FaBed, FaUser, FaMapMarkerAlt } from 'react-icons/fa'
import { Card } from 'react-bootstrap'
import Brochure from './Brochure'


const Description = props => {
  return (
    <div>
      <div className='property-info mb-5'>
        <div className='row'>
          {props.bedroom && (
            <div className='col-md-3 col-sm-6'>
              <div className='single-property-info'>
                <h5><FaBed/>Bedrooms</h5>
                <p>
                  <i className='fa fa-bed' />
                  {props.bedroom}
                </p>
              </div>
            </div>
          )}
          {props.toilet && (
            <div className='col-md-3 col-sm-6'>
              <div className='single-property-info'>
                <h5><FaBath/>Toilet</h5>
                <p>
                  <i className='fa fa-bath' />
                  {props.toilet}
                </p>
              </div>
            </div>
          )}
          {props.area && (
            <div className='col-md-3 col-sm-6'>
              <div className='single-property-info'>
                <h5><FaVectorSquare/>Area</h5>
                <p>
                  <img src={'/assets/img/icons/7.png'} alt='' />
                  {props.area}
                </p>
              </div>
            </div>
          )}
          <div className='col-md-3 col-sm-6'>
            {props.parkingSpace && (
              <div className='single-property-info'>
                <h5>Parking</h5>
                <p>
                  <i className='fa fa-car' />
                  {props.parkingSpace}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      
      <div className='property-news-single-card style-two border-bottom-yellow'>
        
        <div
          dangerouslySetInnerHTML={{
            __html: props.description.childMarkdownRemark.html
          }}
        />
        {/*<Brochure/>*/}
      </div>
     
      
    </div>
    
  )
}

export default Description
