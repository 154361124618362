 import React from 'react';
 



export default function Contact2 () {

        return (
            <div className=' mt-2'>
                <h6 className='text-center'>Book Inspection</h6>
            <form
            className='widget widget-subscribe'
            action={`https://formspree.io/f/xlepznqe`}
            name="Inspection2"
            method="POST" 
            id="subscribe-form" 
            data-netlify="true">


                <div className='rld-single-input'>
                <input className=""
                         type="text" 
                         name="fname" 
                         placeholder="Full Name"
                        required />
                </div>
                
                         
                    <div className='rld-single-input'>
                    <input className=""
                         type="tel" 
                         name="phone" 
                         placeholder="Phone Number"
                        required />
                    </div>
                         
                    
                    
                <div className='rld-single-input'>
                <input 
                         type="email" 
                         name="email" 
                         placeholder="Enter Email Address..."
                        required />
                </div>
            <div className='rld-single-input'>
            <input type='text' name="message" placeholder='Message (optional)'/>
            </div>   
                <button className='btn btn-yellow w-100' type="submit"> Book Inspection 
                </button>
            </form>
            </div>
        );
        
    }