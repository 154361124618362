import { graphql } from 'gatsby'
import PropertyDetails from '../components/PropertyDetails'

export default PropertyDetails

export const query = graphql`
  query propertyQuery($slug: String!) {
    contentfulProperty(slug: { eq: $slug }) {
      ...Property
    }
  }
`
