import React from 'react'
import { FaCheck, FaPhoneAlt, FaWhatsapp } from 'react-icons/fa'

const OtherFeatures = ({ otherFeatures }) => {
  return (
    <div className='property-news-single-card  border-bottom-yellow'>
      <h2 className='h4'>Amenities</h2>
      <div className='row'>
     {otherFeatures    && otherFeatures.map((item, i) => (
          <div className='col-sm-4' key={i}>
            <ul className='rld-list-style mb-3 mb-sm-0'>
              <h2 className='h6 text-black p'>
                <FaCheck /> {item}
              </h2>
            </ul>
          </div>
        ))}
        
      </div>
      <div>
      <a href="tel:+2348101536132" className=" btn btn-danger w-100"><span className=''><FaPhoneAlt className=' text-white mx-1 mt-2 h4'/><span className='text-white mt-1'>Call Agent</span></span></a>
      
      <a href="https://wa.me/2348101536132" className=" mt-2 btn btn-success w-100"><FaWhatsapp className=' mt-2 text-white mx-1  h4'/><span className='text-white'>Send Message on Whatsapp</span></a>
      </div>
    </div>
  )
}

export default OtherFeatures
