import React from 'react'


export default function Contact() {
    return (
        <div>
            <div className='contact'>
        <h6>Book Inspection</h6>
        <form
        action={`https://formspree.io/f/xlepznqe`}
        name="product"
        method="POST"
        data-netlify="true"
        id="subscribe-form"
        >
        <div className='rld-single-input'>
          <input type='text'name="name"  placeholder='Full Name'required />
        </div>
        <div className='rld-single-input'>
          <input type='text' name="email" placeholder='Email'required />
        </div>
        <div className='rld-single-input'>
          <input type='tel' name="tel" placeholder='Phone no'required />
        </div>
        <div className='rld-single-input'>
          <input type='text' name="message" placeholder='Message (optional)'/>
        </div>
        <button className='btn btn-yellow' type="submit">
          Book Inspection
        </button>
        </form>
      </div>
        </div>
    )
}
