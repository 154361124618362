import React from 'react'
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image'
import { Carousel } from 'react-responsive-carousel'
import './slider.css'

const Slider = ({ images }) => {
  return (
    <div>
    <Carousel className="caro">
      {images.map((image, index) => (
        <Img
          className='d-block w-100 mb-0'
          key={index}
          image={getImage(image)}
          alt='slides'
        />
        
        
      ))}
     
    </Carousel>

    
    
    <p className="text-center text-muted">*click arrow by the right of image to swipe*</p>
    </div>
    
  )
  
}

export default Slider
